import PropTypes from "prop-types"
import LocalizedLink from "components/LocalizedLink"
import cc from "classcat"

const LogoHeader = ({ white, Logo, to = "/" }) => {
  return (
    <div className="my-0 flex flex-row justify-between p-0 pb-3">
      <LocalizedLink
        className={cc([
          "no-underline opacity-100 transition hover:opacity-100 focus:opacity-100",
          {
            "text-white": white === true,
            "text-secondary": white === false,
          },
        ])}
        to={to}
      >
        <Logo className="h-10" />
      </LocalizedLink>
    </div>
  )
}

LogoHeader.defaultProps = {
  white: false,
}

LogoHeader.propTypes = {
  white: PropTypes.bool,
  Logo: PropTypes.func.isRequired,
}

export default LogoHeader
